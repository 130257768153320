<template>
  <div id="app-card" class="align-middle">
    <b-card>
      <b-form-group>
          <div v-if="this.cupos >= 1" class='stepTitle'><h2>Registrá a los participantes</h2></div>

        <div v-if="this.cupos === 0" class='stepTitle'><h2>No quedan cupos disponibles</h2></div>
        <div class="align-left" v-if="this.cupos >= 1">
        <label class="required" for="participante1"><b>Participante 1</b></label></div>
      <b-input-group v-if="this.cupos >= 1">
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="participante1"
          id="participante1"
          placeholder="Nombre y apellido"
          v-model="participante1"
          v-bind:class="{ 'is-invalid': errors.includes('participante1') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>

<div class="align-left" v-if="this.cupos >= 2">
          <label for="participante2"><b>Participante 2</b></label>
        </div>
      <b-input-group v-if="this.cupos >= 2">
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="participante2"
          id="participante2"
          placeholder="Nombre y apellido"
          v-model="participante2"
        ></b-form-input>
      </b-input-group>


<div class="align-left" v-if="this.cupos >= 3">
<label for="participante3"><b>Participante 3</b></label>
        </div>
      <b-input-group v-if="this.cupos >= 3">
       <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="participante3"
          id="participante3"
          placeholder="Nombre y apellido"
          v-model="participante3"
        ></b-form-input>
      </b-input-group>


<div class="align-left" v-if="this.cupos >= 4">
<label for="participante4"><b>Participante 4</b></label>
        </div>
      <b-input-group v-if="this.cupos >= 4">
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="participante4"
          id="participante4"
          placeholder="Nombre y apellido"
          v-model="participante4"
        ></b-form-input>
      </b-input-group>

<div class="align-left" v-if="this.cupos >= 5">
<label for="participante5"><b>Participante 5</b></label>
        </div>
      <b-input-group v-if="this.cupos >= 5">
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="participante5"
          id="participante5"
          placeholder="Nombre y apellido"
          v-model="participante5"
        ></b-form-input>
      </b-input-group>


      

      <b-input-group v-if="this.cupos >= 1" class="margtop">
        <b-button
          id="btn-chechform"
          size="lg"
          class="confirm-button"
          @click="CheckForm()"
        >Registrar equipo</b-button>
      </b-input-group>

      <div id="errores" class="disp-none">Tienes campos incompletos</div>
    </b-card>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'idcapitan',
  ],
  data() {
    return {
      cupos: '',
      participante1: '',
      participante2: '',
      participante3: '',
      participante4: '',
      participante5: '',
      hasErrors: false,
      errors: [],
    };
  },
  async created() {
    if (this.$route.query.c) {
      this.$route.query.c
     this.idcapitan = this.$route.query.c; 
    }
    await axios.get(`https://lawn-tennis.wapchita.com/historiales/getGroupV2/${this.idcapitan}`)
    .then((response) =>{
        if (response.data.code != 200) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Algo no funciono bien, te invitamos a escribirle nuevamente al bot")
              document.getElementById('btn-chechform').disabled = true;
            }else{
              console.log(response.data)
              this.cupos = response.data.availability
            }
    })
    .catch((error) => {
    // handle error
      console.log(error);
    })
  },
  methods: {
    async next1() {
          console.log(this.participante1)
      this.$router.push({
        name: 'confirmacionaux',
        params: {
          participante1: this.participante1,
          participante2: this.participante2,
          participante3: this.participante3,
          participante4: this.participante4,
          participante5: this.participante5,
          idcapitan: this.idcapitan,
        },
      });
    },
    async CheckForm() {
      let divErr = document.getElementById("errores")
      this.errors = [];
      if (this.participante1 === '') {
        this.errors.push('participante1');
        divErr.classList.remove("disp-none")
      }
      if (this.participante1.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido de los participantes")
        this.errors.push('participante1');
        divErr.classList.remove("disp-none")
      }
      if (this.participante2 != '') {
        if (this.participante2.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido de los participantes")
        this.errors.push('participante1');
        divErr.classList.remove("disp-none")
        }
      }
      if (this.participante3 != '') {
        if (this.participante3.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido de los participantes")
        this.errors.push('participante1');
        divErr.classList.remove("disp-none")
        }
      }
      if (this.participante4 != '') {
        if (this.participante4.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido de los participantes")
        this.errors.push('participante1');
        divErr.classList.remove("disp-none")
        }
      }

      
      if (this.participante5 != '') {
        if (this.participante5.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido de los participantes")
        this.errors.push('participante1');
        divErr.classList.remove("disp-none")
      }
      }
      
      
      
      if (this.errors.length === 0) {
        divErr.classList.add("disp-none")
        this.next1();
      } 
    },


  },
};
</script>

<style>
  em{
    font-style: normal !important
  }
  button{
    margin: auto
  }
  .confirm-button{
    color: white;
    border-width: 0;
    border-radius: 5px;
    background-color: #2a4fe3;
    font-family: 'GilroyBold', sans-serif;
  }
  .disp-none{
    display: none !important
  }
  #errores{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  }

  .required::after{
  content:" *";
  color: red;
  }
  label{
  margin-bottom: 0px !important;
  margin-top: 0.55rem !important;
  }
  .tituloinput{
  margin-bottom: 10px !important;
  }

  .input-group{
    margin: 0rem !important
  }

  .margtop{
    margin-top: 30px !important;
  }

  .align-left{
    text-align: left !important;
  }
</style>
