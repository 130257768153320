import Vue from 'vue';
import VueRouter from 'vue-router';

import Registro from '../views/RegistroTeam.vue';
import Confirmacion from '../views/ConfirmacionTeam.vue';
import Success from '../views/Success.vue';
import RegistroAux from '../views/RegistroAuxilios.vue';
import ConfirmacionAux from '../views/ConfirmacionAuxilios.vue';
import SuccessAux from '../views/SuccessAuxilios.vue';
import Finalizado from '../views/finalizado.vue';
import Registro1p from '../views/registro1p.vue';
import confirmRed from '../views/success2.vue';
import coloniahijo1 from '../views/registrocoloniahijo1.vue';
import coloniahijo2 from '../views/registrocoloniahijo2.vue';
import coloniahijo3 from '../views/registrocoloniahijo3.vue';
import coloniahijo4 from '../views/registrocoloniahijo4.vue';
import coloniahijo5 from '../views/registrocoloniahijo5.vue';
import disclaimer from '../views/disclaimer.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/disclaimer',
    name: 'disclaimer',
    component: disclaimer,
    props: true,
  },
  {
    path: '/hijo5',
    name: 'hijo5',
    component: coloniahijo5,
    props: true,
  },
  {
    path: '/hijo4',
    name: 'hijo4',
    component: coloniahijo4,
    props: true,
  },
  {
    path: '/hijo3',
    name: 'hijo3',
    component: coloniahijo3,
    props: true,
  },
  {
    path: '/hijo2',
    name: 'hijo2',
    component: coloniahijo2,
    props: true,
  },
  {
    path: '/hijo1',
    name: 'hijo1',
    component: coloniahijo1,
    props: true,
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: confirmRed,
    props: true,
  },
  {
    path: '/registro',
    name: 'registro',
    component: Registro1p,
    props: true,
  },
  {
    path: '/confirmacion',
    name: 'confirmacion',
    component: Confirmacion,
    props: true,
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
    props: true,
  },
  {
    path: '/register',
    name: 'registrouy',
    component: Registro,
    props: true,
  },
  {
    path: '/confirmation',
    name: 'confirmacionaux',
    component: ConfirmacionAux,
    props: true,
  },
  {
    path: '/satisfactorio',
    name: 'successaux',
    component: SuccessAux,
    props: true,
  },
  {
    path: '/fin',
    name: 'fin',
    component: Finalizado,
    props: true,
  }, 
];

const router = new VueRouter({
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

export default router;
