<template>
  <div id="app-card" class="align-middle">
    <b-card>
      <b-form-group>
          <div class='stepTitle'><h2>Registrá a tu equipo</h2></div>
          <b-row class="tituloinput">
            <b-col sm="3">
              <label class="required tituloinput" :for="`categoria`">Cuál es la categoría de tu equipo?</label>
            </b-col>
            <b-col sm="9">
              <b-form-group>
                <b-form-radio-group
                  v-model="categoria"
                  :options="categoriaoptions"
                  name="categoria"
                  v-bind:class="{ 'is-invalid': errors.includes('categoria') }"
                ></b-form-radio-group>
              </b-form-group>
              <div class="invalid-feedback">Campo obligatorio</div>
            </b-col>
          </b-row>
        <div class="align-left">
        <label class="required" for="jugador1"><b>Jugador 1 y Capitán</b></label></div>
      <b-input-group>
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador1"
          id="jugador1"
          placeholder="Nombre y apellido"
          v-model="jugador1"
          v-bind:class="{ 'is-invalid': errors.includes('jugador1') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
          <label class="required" for="jugador2"><b>Jugador 2</b></label>
        </div>
      <b-input-group>
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador2"
          id="jugador2"
          placeholder="Nombre y apellido"
          v-model="jugador2"
          v-bind:class="{ 'is-invalid': errors.includes('jugador2') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
<label class="required" for="jugador3"><b>Jugador 3</b></label>
        </div>
      <b-input-group>
        
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador3"
          id="jugador3"
          placeholder="Nombre y apellido"
          v-model="jugador3"
          v-bind:class="{ 'is-invalid': errors.includes('jugador3') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
<label class="required" for="jugador4"><b>Jugador 4</b></label>
        </div>
      <b-input-group>
        
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador4"
          id="jugador4"
          placeholder="Nombre y apellido"
          v-model="jugador4"
          v-bind:class="{ 'is-invalid': errors.includes('jugador4') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
<label class="required" for="jugador5"><b>Jugador 5</b></label>
        </div>
      <b-input-group>
        
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador5"
          id="jugador5"
          placeholder="Nombre y apellido"
          v-model="jugador5"
          v-bind:class="{ 'is-invalid': errors.includes('jugador5') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
<label class="required" for="jugador6"><b>Jugador 6</b></label>
        </div>
      <b-input-group>
        
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador6"
          id="jugador6"
          placeholder="Nombre y apellido"
          v-model="jugador6"
          v-bind:class="{ 'is-invalid': errors.includes('jugador6') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
<label for="jugador7"><b>Jugador 7</b></label>
        </div>
      <b-input-group>
        
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador7"
          id="jugador7"
          placeholder="Nombre y apellido"
          v-model="jugador7"
          v-bind:class="{ 'is-invalid': errors.includes('jugador7') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
<label for="jugador8"><b>Jugador 8</b></label>
        </div>
      <b-input-group>
        
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador8"
          id="jugador8"
          placeholder="Nombre y apellido"
          v-model="jugador8"
          v-bind:class="{ 'is-invalid': errors.includes('jugador8') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
<div class="align-left">
<label for="jugador9"><b>Jugador 9</b></label>
        </div>
      <b-input-group>
        
        
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador9"
          id="jugador9"
          placeholder="Nombre y apellido"
          v-model="jugador9"
          v-bind:class="{ 'is-invalid': errors.includes('jugador9') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
      <div class="align-left">
<label for="jugador10"><b>Jugador 10</b></label>
        </div>
      <b-input-group>
        <b-input-group-prepend is-text>
          <b-icon icon="person"></b-icon>
        </b-input-group-prepend>
        <b-form-input
          required
          type="text"
          name="jugador10"
          id="jugador10"
          placeholder="Nombre y apellido"
          v-model="jugador10"
          v-bind:class="{ 'is-invalid': errors.includes('jugador10') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>

      

      <b-input-group class="margtop">
        <b-button
          size="lg"
          class="confirm-button"
          @click="CheckForm()"
        >Registrar equipo</b-button>
      </b-input-group>

      <div id="errores" class="disp-none">Tienes campos incompletos</div>
    </b-card>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'idcapitan',
  ],
  data() {
    return {
      categoria: '',
      categoriaoptions: [
        {text: 'Categoría A', value: 'A'},
        {text: 'Categoría B', value: 'B'},
      ],
      jugador1: '',
      jugador2: '',
      jugador3: '',
      jugador4: '',
      jugador5: '',
      jugador6: '',
      jugador7: '',
      jugador8: '',
      jugador9: '',
      jugador10: '',
      hasErrors: false,
      errors: [],
    };
  },
  async created() {
    if (this.$route.query.c) {
      this.$route.query.c
     this.idcapitan = this.$route.query.c; 
    }
    await axios.get(`https://lawn-tennis.wapchita.com/historiales/getGroup/${this.idcapitan}`)
    .then((response) =>{
        console.log(response.data)
        if (response.data.category != "") {
          this.categoria = response.data.category
        }
        if (response.data.p1 != "") {
          this.jugador1 = response.data.p1
        }
        if (response.data.p2 != "") {
          this.jugador2 = response.data.p2
        }
        if (response.data.p3 != "") {
          this.jugador3 = response.data.p3
        }
        if (response.data.p4 != "") {
          this.jugador4 = response.data.p4
        }
        if (response.data.p5 != "") {
          this.jugador5 = response.data.p5
        }
        if (response.data.p6 != "") {
          this.jugador6 = response.data.p6
        }
        if (response.data.p7 != "") {
          this.jugador7 = response.data.p7
        }
        if (response.data.p8 != "") {
          this.jugador8 = response.data.p8
        }
        if (response.data.p9 != "") {
          this.jugador9 = response.data.p9
        }
        if (response.data.p10 != "") {
          this.jugador10 = response.data.p10
        }
    })
    .catch((error) => {
    // handle error
      console.log(error);
    })
  },
  methods: {
    async next1() {
          console.log(this.jugador1)
      this.$router.push({
        name: 'confirmacion',
        params: {
          categoria: this.categoria,
          jugador1: this.jugador1,
          jugador2: this.jugador2,
          jugador3: this.jugador3,
          jugador4: this.jugador4,
          jugador5: this.jugador5,
          jugador6: this.jugador6,
          jugador7: this.jugador7,
          jugador8: this.jugador8,
          jugador9: this.jugador9,
          jugador10: this.jugador10,
          idcapitan: this.idcapitan,
        },
      });
    },
    async CheckForm() {
      let divErr = document.getElementById("errores")
      this.errors = [];
      console.log(this.jugador1.includes(" "));
      if (this.categoria === '') {
        this.errors.push('categoria');
        divErr.classList.remove("disp-none")
      }
      if (this.jugador1 === '') {
        this.errors.push('jugador1');
        divErr.classList.remove("disp-none")
      }
      if (this.jugador1.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido del capitán")
        this.errors.push('jugador1');
        divErr.classList.remove("disp-none")
      }
      if (this.jugador2 === '') {
        this.errors.push('jugador2');
        divErr.classList.remove("disp-none")
      }
      if (this.jugador3 === '') {
        this.errors.push('jugador3');
        divErr.classList.remove("disp-none")
      }
      if (this.jugador4 === '') {
        this.errors.push('jugador4');
        divErr.classList.remove("disp-none")
      }
      if (this.jugador5 === '') {
        this.errors.push('jugador5');
        divErr.classList.remove("disp-none")
      }
      if (this.jugador6 === '') {
        this.errors.push('jugador6');
        divErr.classList.remove("disp-none")
      }
      if (this.errors.length === 0) {
        divErr.classList.add("disp-none")
        this.next1();
      } 
    },


  },
};
</script>

<style>
  em{
    font-style: normal !important
  }
  button{
    margin: auto
  }
  .confirm-button{
    color: white;
    border-width: 0;
    border-radius: 5px;
    background-color: #2a4fe3;
    font-family: 'GilroyBold', sans-serif;
  }
  .disp-none{
    display: none !important
  }
  #errores{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  }

  .required::after{
  content:" *";
  color: red;
  }
  label{
  margin-bottom: 0px !important;
  margin-top: 0.55rem !important;
  }
  .tituloinput{
  margin-bottom: 10px !important;
  }

  .input-group{
    margin: 0rem !important
  }

  .margtop{
    margin-top: 30px !important;
  }

  .align-left{
    text-align: left !important;
  }
  h2{
		margin-bottom: auto;
		font-family: 'MontserratBold';
		font-size: auto;
		color: auto;
		text-align: auto;
	}
	#app{
		background-color: auto;
		color: auto;
	}
  .required{
		font-size: auto;
    color: auto
	}
</style>
