<template>
  <div id="app-card" class="align-middle">
    <b-card header-tag="header" footer-tag="footer">
      <template class="whitefondo" v-slot:header>
        <div class='stepTitle'><h2>Registrá a los participantes</h2></div>
      </template>
      <b-list-group flush>
        <b-list-group-item>
          <strong>Participante 1: </strong><i>{{participante1}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="participante2 != ''">
          <strong>Participante 2: </strong><i>{{participante2}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="participante3 != ''">
          <strong>Participante 3: </strong><i>{{participante3}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="participante4 != ''">
          <strong>Participante 4: </strong><i>{{participante4}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="participante5 != ''">
          <strong>Participante 5: </strong><i>{{participante5}}</i>
        </b-list-group-item> 
      </b-list-group>
      <template v-slot:footer>
        <p>Esta información es correcta?</p>
        <div class="button-container">
          <b-button
              class="volver-bg"
              size="lg"
              @click="confirm(false)"
            >No
          </b-button>
          <b-button
              id="Btn-Aceptar"
              class="bg-color"
              variant="primary"
              size="lg"
              @click="confirm(true)"
            >Si
          </b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'participante1',
    'participante2',
    'participante3',
    'participante4',
    'participante5',
    'idcapitan',
  ],
  data() {
    return {
      hasErrors: false,
      errors: [],
    };
  },
  async created(){
    var link = document.createElement( "link" );
    link.href = "css/estilos.css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );
  },
  methods: {
    async confirm(correctData) {
      if (correctData) {
        document.getElementById('Btn-Aceptar').disabled = true;
        // send data to backend
        await axios.post('https://lawn-tennis.wapchita.com/historiales/setGroupV2/', {
          "id": `${this.idcapitan}`,
          "p1": `${this.participante1}`,
          "p2": `${this.participante2}`,
          "p3": `${this.participante3}`,
          "p4": `${this.participante4}`,
          "p5": `${this.participante5}`,
        })
        .then((response) => {
          if (response.data.code != 200) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("No se puedo guardar su información, le solicitamos que reintente")
              document.getElementById('Btn-Aceptar').disabled = false;
            } else {
              console.log(response.data);
          this.$router.push({
            name: 'successaux',
            params: {},
          });
            }
          
        })
        .catch((error) => {
          console.log(error);
          window.alert("Hubo un error, le solicitamos que reintente")
          document.getElementById('Btn-Aceptar').disabled = false;
        });
      } else {
        this.$router.push({
          name: 'registroaux',
          params: {
            idcapitan: this.idcapitan,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="sass">
  .card-header
    .card-title
      font-family: 'GilroyMedium', sans-serif;
  button
    margin: auto

  #Btn-Aceptar
    color: white;
    border-width: 0;
    border-radius: 5px;
    background-color: #2a4fe3;
    font-family: 'GilroyBold', sans-serif;

  .card-footer
    display: flex
    flex-direction: column

    .button-container
      display: flex
      flex-direction: row
      margin-top: 10px

      button
        flex: 1
        margin: 0 20px

  .bg-color
    background-color: #204BC3 !important

  .volver-bg
    font-family: 'GilroyBold', sans-serif;
    background-color: #C1C2C6 !important
    color: #333333 !important;
    border: none;

  .list-group-item:hover
    z-index: 0 !important

  .whitefondo
   background-color: white !important
</style>
