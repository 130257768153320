<template>
  <div class="align-middle">
		<img class="logolawn" src="../assets/Logo-Moment-Lawn-blanco.png" alt="logo Carrasco Lawn Tennis">
		<div class='stepTitle'><h2>Términos y condiciones.</h2></div>
    <b-card id="form">
      <b-form-group>
        <div class="align-left margin-bottom-custom">
      <div><a href="https://osomcommerce.com/media/INSCRIPCI%C3%93N%20DEPORTISTA%20VIAJES%20Y%20PASEOS.pdf" target="_blank">Ver términos y condiciones</a></div>
      <b-row class="tituloinput margtop">
            <b-col sm="3">
              <label class="required tituloinput" :for="`categoria`">Aceptas los términos y condiciones?</label>
            </b-col>
            <b-col sm="9">
              <b-form-group>
                <b-form-radio-group
                  v-model="categoria"
                  :options="categoriaoptions"
                  name="categoria"
                  v-bind:class="{ 'is-invalid': errors.includes('categoria') }"
                ></b-form-radio-group>
              </b-form-group>
              <div class="invalid-feedback">Campo obligatorio</div>
            </b-col>
      </b-row>
      <b-input-group class="margtop">
        <b-button
          id="btn-chechform"
          size="lg"
          class="confirm-button"
          @click="CheckForm()"
        >Finalizar</b-button>
      </b-input-group>
      <div id="errores" class="disp-none">Tienes campos incompletos</div>
			</b-form-group>
			<div class="osomred">Powered by <a target="_blank" href="https://www.osomdigital.com/"><img class="logoOsom" src="../assets/Moment-Lawn-logo-osom.png" alt="logo de Osom"></a></div>
    </b-card>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'idcapitan',
    'availableHours',
    'hijos',
    'leadsource',
    'participante1',
    'participante1birthdate',
    'participante1ci',
    'participante1talle',
    'horarioshijo1',
    'participante2',
    'participante2birthdate',
    'participante2ci',
    'horarioshijo2',
    'participante3',
    'participante3birthdate',
    'participante3ci',
    'horarioshijo3',
    'participante4',
    'participante4birthdate',
    'participante4ci',
    'horarioshijo4',
    'participante5',
    'participante5birthdate',
    'participante5ci',
    'horarioshijo5',
    'participante2talle',
    'participante3talle',
    'participante4talle',
    'participante5talle',
    'tutornombre',
    'tutoremail',
    'leadsourceevento',
    'tutorwpp'
  ],
  data() {
    return {
	  theme: '#333333',
      categoria: '',
      categoriaoptions: [
        {text: 'Si', value: 1},
        {text: 'No', value: 0},
      ],
      hasErrors: false,
      errors: [],
    };
  },
  async created() {
    var link = document.createElement( "link" );
    link.href = "css/stylesv23.css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );
  },
  methods: {
	selectTime(time) {
      this.selectedTime = time;
      console.log(this.selectedTime);
    },
    async next1() {
      document.getElementById('btn-chechform').disabled = true;
        console.log(this.categoria);
        console.log(this.hijos);
        if (this.categoria === 1 && this.hijos === 1) {
          console.log("hijo1");
          console.log(this.horarioshijo1)
        await axios.post('https://lawn-tennis.wapchita.com/historiales/setGroupV4', {
          "idCRM": this.idcapitan,
	"padre": this.tutornombre,
	"celular": this.tutorwpp,
	"email": this.tutoremail,
	"idEvento": this.leadsourceevento,
	"evento": "Colonia de vacaciones",
	"personas": [{
		"nombre": this.participante1,
		"apellidos": "ND",
		"cumple": this.participante1birthdate,
		"talle": this.participante1talle,
		"cedula": this.participante1ci,
		"tyc": 1,
		"horarios": this.horarioshijo1
	}]
       //asd
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code === 404) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 404, falto información por registrar, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
              //document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 429) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 429, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 500) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 500, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 200) {
            this.$router.push({
            name: 'confirm',
            params: {},
          });
          } else {
            window.alert("Error no identificado, le solicitamos cerrar y probar nuevamente")
          }
          

          //confirm
        })
        .catch((error) => {
          console.log(error);
        });
        }else if (this.categoria === 1 && this.hijos === 2) { //2
            console.log("hijo2");
            await axios.post('https://lawn-tennis.wapchita.com/historiales/setGroupV4', {
          "idCRM": this.idcapitan,
	"padre": this.tutornombre,
	"celular": this.tutorwpp,
	"email": this.tutoremail,
	"idEvento": this.leadsourceevento,
	"evento": "Colonia de vacaciones",
	"personas": [{
		"nombre": this.participante1,
		"apellidos": "ND",
		"cumple": this.participante1birthdate,
		"talle": this.participante1talle,
		"cedula": this.participante1ci,
		"tyc": 1,
		"horarios": this.horarioshijo1
	},{
		"nombre": this.participante2,
		"apellidos": "ND",
		"cumple": this.participante2birthdate,
		"talle": this.participante2talle,
		"cedula": this.participante2ci,
		"tyc": 1,
		"horarios": this.horarioshijo2
	}]
       //asd
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code === 404) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 404, falto información por registrar, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
              //document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 429) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 429, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 500) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 500, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 200) {
            this.$router.push({
            name: 'confirm',
            params: {},
          });
          } else {
            window.alert("Error no identificado, le solicitamos cerrar y probar nuevamente")
          }
          

          //confirm
        })
        .catch((error) => {
          console.log(error);
        });


        } else if (this.categoria === 1 && this.hijos === 3) { //3
            console.log("hijo3");
            await axios.post('https://lawn-tennis.wapchita.com/historiales/setGroupV4', {
          "idCRM": this.idcapitan,
	"padre": this.tutornombre,
	"celular": this.tutorwpp,
	"email": this.tutoremail,
	"idEvento": this.leadsourceevento,
	"evento": "Colonia de vacaciones",
	"personas": [{
		"nombre": this.participante1,
		"apellidos": "ND",
		"cumple": this.participante1birthdate,
		"talle": this.participante1talle,
		"cedula": this.participante1ci,
		"tyc": 1,
		"horarios": this.horarioshijo1
	},{
		"nombre": this.participante2,
		"apellidos": "ND",
		"cumple": this.participante2birthdate,
		"talle": this.participante2talle,
		"cedula": this.participante2ci,
		"tyc": 1,
		"horarios": this.horarioshijo2
	},{
		"nombre": this.participante3,
		"apellidos": "ND",
		"cumple": this.participante3birthdate,
		"talle": this.participante3talle,
		"cedula": this.participante3ci,
		"tyc": 1,
		"horarios": this.horarioshijo3
	}]
       //asd
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code === 404) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 404, falto información por registrar, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
              //document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 429) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 429, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 500) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 500, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 200) {
            this.$router.push({
            name: 'confirm',
            params: {},
          });
          } else {
            window.alert("Error no identificado, le solicitamos cerrar y probar nuevamente")
          }
          

          //confirm
        })
        .catch((error) => {
          console.log(error);
        });





        } else if (this.categoria === 1 && this.hijos === 4) { //4
            console.log("hijo4");
            await axios.post('https://lawn-tennis.wapchita.com/historiales/setGroupV4', {
          "idCRM": this.idcapitan,
	"padre": this.tutornombre,
	"celular": this.tutorwpp,
	"email": this.tutoremail,
	"idEvento": this.leadsourceevento,
	"evento": "Colonia de vacaciones",
	"personas": [{
		"nombre": this.participante1,
		"apellidos": "ND",
		"cumple": this.participante1birthdate,
		"talle": this.participante1talle,
		"cedula": this.participante1ci,
		"tyc": 1,
		"horarios": this.horarioshijo1
	},{
		"nombre": this.participante2,
		"apellidos": "ND",
		"cumple": this.participante2birthdate,
		"talle": this.participante2talle,
		"cedula": this.participante2ci,
		"tyc": 1,
		"horarios": this.horarioshijo2
	},{
		"nombre": this.participante3,
		"apellidos": "ND",
		"cumple": this.participante3birthdate,
		"talle": this.participante3talle,
		"cedula": this.participante3ci,
		"tyc": 1,
		"horarios": this.horarioshijo3
	},{
		"nombre": this.participante4,
		"apellidos": "ND",
		"cumple": this.participante4birthdate,
		"talle": this.participante4talle,
		"cedula": this.participante4ci,
		"tyc": 1,
		"horarios": this.horarioshijo4
	}]
       //asd
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code === 404) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 404, falto información por registrar, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
              //document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 429) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 429, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 500) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 500, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 200) {
            this.$router.push({
            name: 'confirm',
            params: {},
          });
          } else {
            window.alert("Error no identificado, le solicitamos cerrar y probar nuevamente")
          }
          

          //confirm
        })
        .catch((error) => {
          console.log(error);
        });






        } else if (this.categoria === 1 && this.hijos === 5) { //5
            console.log("hijo5");
            await axios.post('https://lawn-tennis.wapchita.com/historiales/setGroupV4', {
          "idCRM": this.idcapitan,
	"padre": this.tutornombre,
	"celular": this.tutorwpp,
	"email": this.tutoremail,
	"idEvento": this.leadsourceevento,
	"evento": "Colonia de vacaciones",
	"personas": [{
		"nombre": this.participante1,
		"apellidos": "ND",
		"cumple": this.participante1birthdate,
		"talle": this.participante1talle,
		"cedula": this.participante1ci,
		"tyc": 1,
		"horarios": this.horarioshijo1
	},{
		"nombre": this.participante2,
		"apellidos": "ND",
		"cumple": this.participante2birthdate,
		"talle": this.participante2talle,
		"cedula": this.participante2ci,
		"tyc": 1,
		"horarios": this.horarioshijo2
	},{
		"nombre": this.participante3,
		"apellidos": "ND",
		"cumple": this.participante3birthdate,
		"talle": this.participante3talle,
		"cedula": this.participante3ci,
		"tyc": 1,
		"horarios": this.horarioshijo3
	},{
		"nombre": this.participante4,
		"apellidos": "ND",
		"cumple": this.participante4birthdate,
		"talle": this.participante4talle,
		"cedula": this.participante4ci,
		"tyc": 1,
		"horarios": this.horarioshijo4
	},{
		"nombre": this.participante5,
		"apellidos": "ND",
		"cumple": this.participante5birthdate,
		"talle": this.participante5talle,
		"cedula": this.participante5ci,
		"tyc": 1,
		"horarios": this.horarioshijo5
	}]
       //asd
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.code === 404) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 404, falto información por registrar, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
              //document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 429) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 429, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 500) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("Error 500, le recomendamos que cierre e intente nuevamente")
              document.getElementById('btn-chechform').disabled = false;
          } else if (response.data.code === 200) {
            this.$router.push({
            name: 'confirm',
            params: {},
          });
          } else {
            window.alert("Error no identificado, le solicitamos cerrar y probar nuevamente")
          }
          

          //confirm
        })
        .catch((error) => {
          console.log(error);
        });
        } else {
          console.log("rechazo");
          window.location.replace("https://wa.me/59893413880");
          window.close();
        }


    },
    async CheckForm() {
      let divErr = document.getElementById("errores")
      this.errors = [];
		if (this.categoria === '') {
		this.errors.push('categoria');
        divErr.classList.remove("disp-none")
		}
      if (this.errors.length === 0) {
        divErr.classList.add("disp-none")
        this.next1();
      } 
    },


  },
};
</script>

<style>
  em{
    font-style: normal !important
  }
  button{
    margin: auto
  }
  .confirm-button{
    border-width: 0;
    border-radius: 5px;
    font-family: 'MontserratMedium';
  }
  .disp-none{
    display: none !important
  }
  #errores{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  }

  .required::after{
  content:" *";
  color: red;
  }
  label{
  margin-bottom: 0px !important;
  margin-top: 0.55rem !important;
  }
  .tituloinput{
  margin-bottom: 10px !important;
  }

  .input-group{
    margin: 0rem !important
  }

  .margtop{
    margin-top: 1rem !important;
  }

  .align-left{
    text-align: left !important;
  }

	.col-custom{
    margin-bottom: 0px !important
	}
  .btn-custom { 
		width: 100%;
		margin: 0px !important
	}
	/*h2{
		margin-bottom: 1.8rem;
		font-family: 'MontserratBold';
		font-size: 200%;
		color: white;
		text-align: left;
	}
	#app{
		background-color: #fa4f48;
		color: white;
	}*/
	#form{
		background-color: white !important;
		color: black !important;
		border-radius: 15px;
		box-shadow: none;
		border: none;
		margin-bottom: 5px;
		margin-left: 5px;
		margin-right: 5px;
	}
	.btn-danger:hover{
	 box-shadow: none !important;
	}
	.btn-outline-danger{
	 box-shadow: none !important;
	 color: #fa4f48 !important;
	 border-color: #fa4f48 !important;
	}
	.btn-danger{
		color: white !important;
		background-color: #ed7777 !important;
		border-color: #ed7777 !important;
	}
	#btn-chechform{
		color: white !important;
		background-color: #fa4f48 !important;
		font-size: 100%;
		padding-left: 1.5rem;
    padding-right: 1.5rem;
		padding-bottom: 1rem;
    padding-top: 1rem;
	}	
	.osomred{
		color: #fa4f48 !important;
		margin-top: 1.4rem;
		font-size: 70%;
	}
	.logolawn{
		margin-top: 5px;
		height: 110px;
		margin-bottom: 1.3rem;
	}
	.logoOsom{
		width: 15% !important;
	}
	.required{
		font-size: 100%;
    color: #404041;
	}
	.inputcustom{
		background-color: #f5f5f9;
		border-color: #cfd4d9;
		border-radius: 0px 15px 15px 0px;
	}
	.tituloinput .active{
		background-color: #fa4f48 !important;
	}
  input{
    border-style: solid !important;
    border-width: 0px 0px 2px 0px !important; /* 25px top, 10px right, 4px bottom and 35px left */
    border-color: #fa4f48 !important;
    background-color: white !important;
    margin-bottom: 25px;
  }
  select{
    border-style: solid !important;
    border-width: 0px 0px 2px 0px !important; /* 25px top, 10px right, 4px bottom and 35px left */
    border-color: #fa4f48 !important;
    background-color: white !important;
    margin-bottom: 25px !important;
  }
  .margin-bottom-custom{
    margin-bottom: 15px !important;
  }
</style>
