<template>
  <div class="align-middle">
		<img class="logolawn" src="../assets/Logo-Moment-Lawn-blanco.png" alt="logo Carrasco Lawn Tennis">
    <div class='stepTitle'><h2>Quinto/a Hijo/a</h2></div>
    <b-card id="form">
      <b-form-group>
        <div class="align-left margin-bottom-custom">
        <label class="required" for="participante5">Información del quinto hijo</label></div>
      <b-input-group>
        <b-form-input
		      class=""
          required
          type="text"
          name="participante5"
          id="participante5"
          placeholder="Nombre y apellido"
          v-model="participante5"
          v-bind:class="{ 'is-invalid': errors.includes('participante5') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
      <b-input-group>
        <b-form-input
		  class="inputcustom"
          required
          type="text"
          name="participante5birthdate"
          id="participante5birthdate"
          placeholder="Fecha de nacimiento en formato 16/01/2010"
          v-model="participante4birthdate"
          v-bind:class="{ 'is-invalid': errors.includes('participante5birthdate') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
      <b-input-group>
        <b-form-input
		      class="inputcustom"
          required
          type="number"
          name="participante5ci"
          id="participante5ci"
          placeholder="Cédula de identidad"
          v-model="participante5ci"
          v-bind:class="{ 'is-invalid': errors.includes('participante5ci') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
      <b-row class="tituloinput margtop">
            <b-col sm="3">
              <label class="required tituloinput" :for="`categoria`">Elegi el talle para la remera</label>
            </b-col>
            <b-col sm="9">
              <b-form-group>
                <b-form-radio-group
                  v-model="categoria"
                  :options="categoriaoptions"
                  name="categoria"
                  v-bind:class="{ 'is-invalid': errors.includes('categoria') }"
                ></b-form-radio-group>
              </b-form-group>
              <div class="invalid-feedback">Campo obligatorio</div>
            </b-col>
      </b-row>
      <b-row class="tituloinput margtop">
            <b-col sm="3">
              <h4 class="required" for="">Elegí el horario</h4>
              <label class="required tituloinput" :for="`categoria`">Diciembre</label>
            </b-col>
            <b-col sm="9">
              <b-form-group>
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  buttons
                  class="btn margin-btns-custom"
                  button-variant="danger"
                  v-model="selectedDic"
                  :options="optionsDic"
                  :aria-describedby="ariaDescribedby"
                  v-bind:class="{ 'is-invalid': errors.includes('horario') }"
                ></b-form-checkbox-group>
              </b-form-group>
              <div class="invalid-feedback">Campo obligatorio</div>
            </b-col>
      </b-row>
      <b-row class="tituloinput margtop">
            <b-col sm="3">
              <label class="required tituloinput" :for="`categoria`">Enero</label>
            </b-col>
            <b-col sm="9">
              <b-form-group>
                <b-form-checkbox-group
                  id="checkbox-group-2"
                  buttons
                  class="btn margin-btns-custom"
                  button-variant="danger"
                  v-model="selectedEne"
                  :options="optionsEne"
                  :aria-describedby="ariaDescribedby"
                ></b-form-checkbox-group>
              </b-form-group>
              <div class="invalid-feedback">Campo obligatorio</div>
            </b-col>
      </b-row>
      <b-row class="tituloinput margtop">
            <b-col sm="3">
              <label class="required tituloinput" :for="`categoria`">Febrero</label>
            </b-col>
            <b-col sm="9">
              <b-form-group>
                <b-form-checkbox-group
                  id="checkbox-group-3"
                  buttons
                  class="btn margin-btns-custom"
                  button-variant="danger"
                  v-model="selectedFeb"
                  :options="optionsFeb"
                  :aria-describedby="ariaDescribedby"
                ></b-form-checkbox-group>
              </b-form-group>
              <div class="invalid-feedback">Campo obligatorio</div>
            </b-col>
      </b-row>
			
      <b-input-group class="margtop">
        <b-button
          id="btn-chechform"
          size="lg"
          class="confirm-button"
          @click="CheckForm()"
        >Registrarse</b-button>
      </b-input-group>
      <div id="errores" class="disp-none">Tienes campos incompletos</div>
			</b-form-group>
			<div class="osomred">Powered by <a target="_blank" href="https://www.osomdigital.com/"><img class="logoOsom" src="../assets/Moment-Lawn-logo-osom.png" alt="logo de Osom"></a></div>
    </b-card>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'idcapitan',
    'availableHours',
    'infows',
    'hijos',
    'leadsource',
    'tutornombre',
    'tutoremail',
    'tutorwpp',
    'participante1',
    'participante1birthdate',
    'participante1ci',
    'participante1talle',
    'horarioshijo1',
    'participante2',
    'participante2birthdate',
    'participante2ci',
    'horarioshijo2',
    'participante2talle',
    'participante3',
    'participante3birthdate',
    'participante3ci',
    'horarioshijo3',
    'participante3talle',
    'participante4',
    'participante4birthdate',
    'participante4ci',
    'horarioshijo4',
    'participante4talle',
    'leadsourceevento'
  ],
  data() {
    return {
      selectedDic: [], // Must be an array reference!
      optionsDic: [],
      selectedEne: [], // Must be an array reference!
      optionsEne: [],
      selectedFeb: [], // Must be an array reference!
      optionsFeb: [],
			theme: '#333333',
      cupos: '',
			categoria: '',
      categoriaoptions: [
        {text: '4', value: 4},
        {text: '6', value: 6},
        {text: '8', value: 8},
      ],
      participante5: '',
      participante5birthdate: '',
      participante5ci: '',
			selectedTime: null,
      hasErrors: false,
      errors: [],
    };
  },
  async created() {
    var link = document.createElement( "link" );
    link.href = "css/stylesv23.css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );
    console.log(this.availableHours);
    this.optionsDic = this.availableHours.availableHourDiciembre
    this.optionsEne = this.availableHours.availableHourEnero
    this.optionsFeb= this.availableHours.availableHourFebrero
  },
  methods: {
		selectTime(time) {
      this.selectedTime = time;
      console.log(this.selectedTime);
    },
    async next1() {
      document.getElementById('btn-chechform').disabled = true;
      console.log(this.selectedDic);
      console.log(this.selectedEne);
      console.log(this.selectedFeb);
      var myDate = this.participante5birthdate.split("/");
      var newDate = new Date( myDate[2], myDate[1] - 1, myDate[0]);
      console.log(newDate.getTime());
      this.participante5birthdate = newDate.getTime()
          this.$router.push({
              name: 'disclaimer',
              params: {
                tutorwpp:this.tutorwpp,
                tutornombre: this.tutornombre,
                tutoremail: this.tutoremail,
                hijos: this.hijos,
                idcapitan: this.idcapitan,
                leadsource: this.leadsource,
                participante1: this.participante1,
                participante1birthdate: this.participante1birthdate,
                participante1ci: this.participante1ci,
                participante1talle: this.participante1talle,
                horarioshijo1: this.horarioshijo1,
                participante2: this.participante2,
                participante2birthdate: this.participante2birthdate,
                participante2ci: this.participante2ci,
                participante2talle: this.participante2talle,
                horarioshijo2:this.horarioshijo2,
                participante3: this.participante3,
                participante3birthdate: this.participante3birthdate,
                participante3ci: this.participante3ci,
                participante3talle: this.participante3talle,
                horarioshijo3:this.horarioshijo3, 
                participante4: this.participante4,
                participante4birthdate: this.participante4birthdate,
                participante4ci: this.participante4ci,
                participante4talle: this.participante4talle,
                horarioshijo4:this.horarioshijo4,
                participante5: this.participante5,
                participante5birthdate: this.participante5birthdate,
                participante5ci: this.participante5ci,
                participante5talle: this.categoria,
                horarioshijo5:[this.selectedDic, this.selectedEne, this.selectedFeb],
                leadsourceevento: this.leadsourceevento
              },
            });
        
    },
    async CheckForm() {
      let divErr = document.getElementById("errores")
      this.errors = [];
      console.log(this.selectedDic.length);
      console.log(this.selectedEne.length);
      console.log(this.selectedFeb.length);
      if (this.participante5 === '') {
        this.errors.push('participante5');
        divErr.classList.remove("disp-none")
      }
      if (this.participante5.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido de los participantes")
        this.errors.push('participante5');
        divErr.classList.remove("disp-none")
      }  
      if (this.participante5birthdate === '') {
        this.errors.push('participante5birthdate');
        divErr.classList.remove("disp-none")
      }
      if (this.participante5ci === '') {
        this.errors.push('participante5ci');
        divErr.classList.remove("disp-none")
      }
      if (this.participante5birthdate.includes('/') === false) {
        window.alert("Es necesario agregar la fecha de nacimiento en el formato indicado")
        this.errors.push('participante5birthdate');
        divErr.classList.remove("disp-none")
      }  
      if (this.selectedDic.length === 0 && this.selectedEne.length === 0 && this.selectedFeb.length === 0) {
        window.alert("Es necesario seleccionar almenos un horario")
        this.errors.push('participante1ci');
        divErr.classList.remove("disp-none")
      }
			if (this.categoria === '') {
        this.errors.push('categoria');
        divErr.classList.remove("disp-none")
      }
      if (this.errors.length === 0) {
        divErr.classList.add("disp-none")
        this.next1();
      } 
    },


  },
};
</script>

<style>
  em{
    font-style: normal !important
  }
  button{
    margin: auto
  }
  .confirm-button{
    border-width: 0;
    border-radius: 5px;
    font-family: 'MontserratMedium';
  }
  .disp-none{
    display: none !important
  }
  #errores{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  }

  .required::after{
  content:" *";
  color: red;
  }
  label{
  margin-bottom: 0px !important;
  margin-top: 0.55rem !important;
  }
  .tituloinput{
  margin-bottom: 10px !important;
  }

  .input-group{
    margin: 0rem !important
  }

  .margtop{
    margin-top: 1rem !important;
  }

  .align-left{
    text-align: left !important;
  }

	.col-custom{
    margin-bottom: 0px !important
	}
  .btn-custom { 
		width: 100%;
		margin: 0px !important
	}
	/*h2{
		margin-bottom: 1.8rem;
		font-family: 'MontserratBold';
		font-size: 200%;
		color: white;
		text-align: left;
	}
	#app{
		background-color: #fa4f48;
		color: white;
	}*/
	#form{
		background-color: white !important;
		color: black !important;
		border-radius: 15px;
		box-shadow: none;
		border: none;
		margin-bottom: 5px;
		margin-left: 5px;
		margin-right: 5px;
	}
	.btn-danger:hover{
	 box-shadow: none !important;
	}
	.btn-outline-danger{
	 box-shadow: none !important;
	 color: #fa4f48 !important;
	 border-color: #fa4f48 !important;
	}
	.btn-danger{
		color: white !important;
		background-color: #ed7777 !important;
		border-color: #ed7777 !important;
	}
	#btn-chechform{
		color: white !important;
		background-color: #fa4f48 !important;
		font-size: 100%;
		padding-left: 1.5rem;
    padding-right: 1.5rem;
		padding-bottom: 1rem;
    padding-top: 1rem;
	}	
	.osomred{
		color: #fa4f48 !important;
		margin-top: 1.4rem;
		font-size: 70%;
	}
	.logolawn{
		margin-top: 5px;
		height: 110px;
		margin-bottom: 1.3rem;
	}
	.logoOsom{
		width: 15% !important;
	}
	.required{
		font-size: 100%;
    color: #404041;
	}
	.inputcustom{
		background-color: #f5f5f9;
		border-color: #cfd4d9;
		border-radius: 0px 15px 15px 0px;
	}
	.tituloinput .active{
		background-color: #fa4f48 !important;
	}
  #checkbox-group-1 label{
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
  }
  #checkbox-group-2 label{
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
  }
  #checkbox-group-3 label{
    margin-left: 20px;
    margin-right: 20px;
    width: 100%;
  }
  input{
    border-style: solid !important;
    border-width: 0px 0px 2px 0px !important; /* 25px top, 10px right, 4px bottom and 35px left */
    border-color: #fa4f48 !important;
    background-color: white !important;
    margin-bottom: 25px !important;
  }
  .btn-outline-danger .active{
	 box-shadow: none !important;
	 color: white !important;
	}
  .margin-bottom-custom{
    margin-bottom: 15px !important;
  }
</style>
