<template>
  <div class="align-middle">
		<img class="logolawn" src="../assets/Logo-Moment-Lawn-blanco.png" alt="logo Carrasco Lawn Tennis">
		<div class='stepTitle'><h2>Registrate a la<br>Colonia de verano.</h2></div>
    <b-card id="form">
      <b-form-group>
        <div class="align-left margin-bottom-custom">
        <label class="required" for="tutornombre">Información del tutor</label></div>
      <b-input-group>
        <b-form-input
          required
          type="text"
          name="tutornombre"
          id="tutornombre"
          placeholder="Nombre y apellido"
          v-model="tutornombre"
          v-bind:class="{ 'is-invalid': errors.includes('tutornombre') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
			<b-input-group>
        <b-form-input
          required
          type="text"
          name="tutoremail"
          id="tutoremail"
          placeholder="Email"
          v-model="tutoremail"
          v-bind:class="{ 'is-invalid': errors.includes('tutoremail') }"
        ></b-form-input>
        <div class="invalid-feedback">Campo obligatorio</div>
      </b-input-group>
      <div>
        <b-form-select v-bind:class="{ 'is-invalid': errors.includes('selected') }" v-model="selected" :options="options"></b-form-select>
        <div class="invalid-feedback">Campo obligatorio</div>
      </div>
			<div>
        <label class="align-left">La colonia es en diciembre, enero y febrero.<br>
Los horarios son de 9:00 a 13:00 Hrs. y de 15:00 a 19:00 Hrs.</label>
      </div>
      <b-input-group class="margtop">
        <b-button
          id="btn-chechform"
          size="lg"
          class="confirm-button"
          @click="CheckForm()"
        >Registrarse</b-button>
      </b-input-group>
      <div id="errores" class="disp-none">Tienes campos incompletos</div>
			</b-form-group>
			<div class="osomred">Powered by <a target="_blank" href="https://www.osomdigital.com/"><img class="logoOsom" src="../assets/Moment-Lawn-logo-osom.png" alt="logo de Osom"></a></div>
    </b-card>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'idcapitan',
  ],
  data() {
    return {
			theme: '#333333',
      cupos: '',
      selected: 'placeholder',
      idcapitan: '',
      leadsource: '',
      options: [
        { value: 'placeholder', text: 'Seleccione la cantidad de hijos' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
      ],
			tutornombre: '',
      tutoremail: '',
      hasErrors: false,
      errors: [],
    };
  },
  async created() {
    if (this.$route.query.c) {
     this.$route.query.c
     this.idcapitan = this.$route.query.c; 
     this.$route.query.ls
     this.leadsource = this.$route.query.ls
    }
    var link = document.createElement( "link" );
    link.href = "css/stylesv23.css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );
  },
  methods: {
		selectTime(time) {
      this.selectedTime = time;
      console.log(this.selectedTime);
    },
    async next1() {
      document.getElementById('btn-chechform').disabled = true;
        await axios.get(`https://lawn-tennis.wapchita.com/historiales/getGroupV4/${this.idcapitan}/${this.leadsource}/${this.selected}`)
        .then((response) => {
          if (response.data.code != 200) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("No se puedo guardar su información, le solicitamos que reintente")
              document.getElementById('btn-chechform').disabled = false;
            } else if (response.data.expired === true) {
              window.alert("El evento ya expiro, cualquier cosa comuniquese al 094197353")
              document.getElementById('btn-chechform').disabled = false;
            } else if (response.data.queue === true) {
              window.alert("No hay cupos disponibles, vuelva al bot para anotarse en la cola")
              document.getElementById('btn-chechform').disabled = false;
            } else {
              console.log(response.data);
              this.$router.push({
              name: 'hijo1',
              params: {
                tutorwpp: response.data.phone,
                tutornombre: this.tutornombre,
                tutoremail: this.tutoremail,
                hijos: this.selected,
                availableHours: response.data.availability,
                idcapitan: this.idcapitan,
                leadsource: this.leadsource,
                leadsourceevento: response.data.event.id
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
          window.alert("Hubo un error, le solicitamos que reintente")
          document.getElementById('btn-chechform').disabled = false;
        });
    },
    async CheckForm() {
      let divErr = document.getElementById("errores")
      this.errors = [];
      if (this.tutornombre === '') {
        this.errors.push('tutornombre');
        divErr.classList.remove("disp-none")
      }
      if (this.tutornombre.includes(' ') === false) {
        window.alert("Es necesario agregar nombre y apellido de los participantes")
        this.errors.push('tutornombre');
        divErr.classList.remove("disp-none")
      }  
			if (this.tutoremail === '') {
        this.errors.push('tutoremail');
        divErr.classList.remove("disp-none")
      }
			if (this.selected === 'placeholder') {
				this.errors.push('selected');
        divErr.classList.remove("disp-none")
			}
      if (this.errors.length === 0) {
        divErr.classList.add("disp-none")
        this.next1();
      } 
    },


  },
};
</script>

<style>
  em{
    font-style: normal !important
  }
  button{
    margin: auto
  }
  .confirm-button{
    border-width: 0;
    border-radius: 5px;
    font-family: 'MontserratMedium';
  }
  .disp-none{
    display: none !important
  }
  #errores{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
  }

  .required::after{
  content:" *";
  color: red;
  }
  label{
  margin-bottom: 0px !important;
  margin-top: 0.55rem !important;
  }
  .tituloinput{
  margin-bottom: 10px !important;
  }

  .input-group{
    margin: 0rem !important
  }

  .margtop{
    margin-top: 1rem !important;
  }

  .align-left{
    text-align: left !important;
  }

	.col-custom{
    margin-bottom: 0px !important
	}
  .btn-custom { 
		width: 100%;
		margin: 0px !important
	}
	/*h2{
		margin-bottom: 1.8rem;
		font-family: 'MontserratBold';
		font-size: 200%;
		color: white;
		text-align: left;
	}
	#app{
		background-color: #fa4f48;
		color: white;
	}*/
	#form{
		background-color: white !important;
		color: black !important;
		border-radius: 15px;
		box-shadow: none;
		border: none;
		margin-bottom: 5px;
		margin-left: 5px;
		margin-right: 5px;
	}
	.btn-danger:hover{
	 box-shadow: none !important;
	}
	.btn-outline-danger{
	 box-shadow: none !important;
	 color: #fa4f48 !important;
	 border-color: #fa4f48 !important;
	}
	.btn-danger{
		color: white !important;
		background-color: #ed7777 !important;
		border-color: #ed7777 !important;
	}
	#btn-chechform{
		color: white !important;
		background-color: #fa4f48 !important;
		font-size: 100%;
		padding-left: 1.5rem;
    padding-right: 1.5rem;
		padding-bottom: 1rem;
    padding-top: 1rem;
	}	
	.osomred{
		color: #fa4f48 !important;
		margin-top: 1.4rem;
		font-size: 70%;
	}
	.logolawn{
		margin-top: 5px;
		height: 110px;
		margin-bottom: 1.3rem;
	}
	.logoOsom{
		width: 15% !important;
	}
	.required{
		font-size: 100%;
    color: #404041;
	}
	.inputcustom{
		background-color: #f5f5f9;
		border-color: #cfd4d9;
		border-radius: 0px 15px 15px 0px;
	}
	.tituloinput .active{
		background-color: #fa4f48 !important;
	}
  input{
    border-style: solid !important;
    border-width: 0px 0px 2px 0px !important; /* 25px top, 10px right, 4px bottom and 35px left */
    border-color: #fa4f48 !important;
    background-color: white !important;
    margin-bottom: 25px;
  }
  select{
    border-style: solid !important;
    border-width: 0px 0px 2px 0px !important; /* 25px top, 10px right, 4px bottom and 35px left */
    border-color: #fa4f48 !important;
    background-color: white !important;
    margin-bottom: 25px !important;
  }
  .margin-bottom-custom{
    margin-bottom: 15px !important;
  }
</style>
