<template>
  <div id="app-card" class="align-middle">
    <b-card header-tag="header" footer-tag="footer">
      <template class="whitefondo" v-slot:header>
        <div class='stepTitle'><h2>Registrá a tu equipo</h2></div>
      </template>
      <b-list-group flush>
        <b-list-group-item>
          <strong>Categoría </strong><i>{{categoria}}</i>
        </b-list-group-item>
        <b-list-group-item>
          <strong>Jugador 1 y Capitán: </strong><i>{{jugador1}}</i>
        </b-list-group-item>
        <b-list-group-item>
          <strong>Jugador 2: </strong><i>{{jugador2}}</i>
        </b-list-group-item>
        <b-list-group-item>
          <strong>Jugador 3: </strong><i>{{jugador3}}</i>
        </b-list-group-item>
        <b-list-group-item>
          <strong>Jugador 4: </strong><i>{{jugador4}}</i>
        </b-list-group-item>
        <b-list-group-item>
          <strong>Jugador 5: </strong><i>{{jugador5}}</i>
        </b-list-group-item>
        <b-list-group-item>
          <strong>Jugador 6: </strong><i>{{jugador6}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="jugador7 != ''">
          <strong>Jugador 7: </strong><i>{{jugador7}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="jugador8 != ''">
          <strong>Jugador 8: </strong><i>{{jugador8}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="jugador9 != ''">
          <strong>Jugador 9: </strong><i>{{jugador9}}</i>
        </b-list-group-item>
        <b-list-group-item v-if="jugador10 != ''">
          <strong>Jugador 10: </strong><i>{{jugador10}}</i>
        </b-list-group-item>
        
      </b-list-group>
      <template v-slot:footer>
        <p>Esta información es correcta?</p>
        <div class="button-container">
          <b-button
              class="volver-bg"
              size="lg"
              @click="confirm(false)"
            >No
          </b-button>
          <b-button
              id="Btn-Aceptar"
              class="bg-color"
              variant="primary"
              size="lg"
              @click="confirm(true)"
            >Si
          </b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'categoria',
    'jugador1',
    'jugador2',
    'jugador3',
    'jugador4',
    'jugador5',
    'jugador6',
    'jugador7',
    'jugador8',
    'jugador9',
    'jugador10',
    'idcapitan',
  ],
  data() {
    return {
      hasErrors: false,
      errors: [],
    };
  },
  async created(){
    var link = document.createElement( "link" );
    link.href = "css/estilos.css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );
  },
  methods: {
    async confirm(correctData) {
      if (correctData) {
        document.getElementById('Btn-Aceptar').disabled = true;
        // send data to backend
        await axios.post('https://lawn-tennis.wapchita.com/historiales/setGroup/', {
          "id": `${this.idcapitan}`,
          "category": `${this.categoria}`,
          "p1": `${this.jugador1}`,
          "p2": `${this.jugador2}`,
          "p3": `${this.jugador3}`,
          "p4": `${this.jugador4}`,
          "p5": `${this.jugador5}`,
          "p6": `${this.jugador6}`,
          "p7": `${this.jugador7}`,
          "p8": `${this.jugador8}`,
          "p9": `${this.jugador9}`,
          "p10": `${this.jugador10}`
        })
        .then((response) => {
          if (response.data.code != 200) {
              console.error('error registro de pedido');
              console.error(response.data);
              window.alert("No se puedo guardar su información, le solicitamos que reintente")
              document.getElementById('Btn-Aceptar').disabled = false;
            } else {
              console.log(response.data);
          this.$router.push({
            name: 'success',
            params: {},
          });
            }
          
        })
        .catch((error) => {
          console.log(error);
          window.alert("Hubo un error, le solicitamos que reintente")
          document.getElementById('Btn-Aceptar').disabled = false;
        });
      } else {
        this.$router.push({
          name: 'registrouy',
          params: {
            idcapitan: this.idcapitan,
          },
        });
      }
    },
  },
};
</script>

<style scoped lang="sass">
  .card-header
    .card-title
      font-family: 'GilroyMedium', sans-serif;
  button
    margin: auto

  #Btn-Aceptar
    color: white;
    border-width: 0;
    border-radius: 5px;
    background-color: #2a4fe3;
    font-family: 'GilroyBold', sans-serif;

  .card-footer
    display: flex
    flex-direction: column

    .button-container
      display: flex
      flex-direction: row
      margin-top: 10px

      button
        flex: 1
        margin: 0 20px

  .bg-color
    background-color: #204BC3 !important

  .volver-bg
    font-family: 'GilroyBold', sans-serif;
    background-color: #C1C2C6 !important
    color: #333333 !important;
    border: none;

  .list-group-item:hover
    z-index: 0 !important

  .whitefondo
   background-color: white !important
</style>
