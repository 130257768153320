<template>
  <div id="app-card" class="align-middle mx-auto">
    <div id="styles1">
      <b-list-group flush>
          <div>
             <img src="../assets/ticksuccess.png" alt="">
          </div>
        <div class='stepTitle'><h2>🙌 <b>Felicitaciones</b> 🙌</h2></div>
        <b-list-group-item>
          <strong>Fuiste registrado correctamente
        </b-list-group-item>
      </b-list-group>
      <b-input-group class="margtop">
        <b-button
          id="btn-chechform"
          size="lg"
          class="confirm-button"
          @click="confirm()"
        >Volver a Whatsapp</b-button>
      </b-input-group>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'categoria',
    'jugador1',
    'jugador2',
    'jugador3',
    'jugador4',
    'jugador5',
    'jugador6',
    'jugador7',
    'jugador8',
    'jugador9',
    'jugador10',
    'idcapitan',
  ],
  data() {
    return {
      hasErrors: false,
      errors: [],
    };
  },
  async created(){
    /*var link = document.createElement( "link" );
    link.href = "css/estilos.css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );*/
  },
  methods: {
    confirm() {
      window.location.replace("https://wa.me/59893413880");
    },
  },
};
</script>

<style scoped lang="sass">
  .card-header
    .card-title
      font-family: 'GilroyMedium', sans-serif;
  button
    margin: auto

  #Btn-Aceptar
    color: white;
    border-width: 0;
    border-radius: 5px;
    background-color: #2a4fe3;
    font-family: 'GilroyBold', sans-serif;

  .card-footer
    display: flex
    flex-direction: column

    .button-container
      display: flex
      flex-direction: row
      margin-top: 10px

      button
        flex: 1
        margin: 0 20px

  .bg-color
    background-color: #204BC3 !important

  .volver-bg
    font-family: 'GilroyBold', sans-serif;
    background-color: #f5f5f5 !important
    color: #333333 !important;
    border: none;

  .alignhorizontal
    horizontal-align: middle;

  .list-group-flush>.list-group-item
    border-width: 0px;

  #styles1
    border-radius: 0px !important
    border: 0px !important
    box-shadow: none !important
</style>