<template>
  <div id="app-card" class="align-middle">
    <div id="styles">
      <h2>¡Gracias por<br>registrarte!<br>Nos vemos en breve.</h2>
      <b-input-group class="margtop">
        <b-button
          id="btn-chechform2"
          size="lg"
          class="confirm-button"
          @click="confirm()"
        >Volver a Whatsapp</b-button>
      </b-input-group>
    </div>
    <div class="osomwhite">
      <p>Powered by <a target="_blank" href="https://www.osomdigital.com/"><img class="logoOsom" src="../assets/Moment-Lawn-logo-osom-blanco.png" alt=""></a></p>
    </div>
  </div>
</template>

<script>
const axios = require('axios').default;

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
axios
  .defaults
  .headers
  .post['Access-Control-Allow-Methods'] = 'GET, POST, OPTIONS, PUT, PATCH, DELETE';

export default {
  props: [
    'categoria',
    'jugador1',
    'jugador2',
    'jugador3',
    'jugador4',
    'jugador5',
    'jugador6',
    'jugador7',
    'jugador8',
    'jugador9',
    'jugador10',
    'idcapitan',
  ],
  data() {
    return {
      hasErrors: false,
      errors: [],
    };
  },
  async created(){
    /*var link = document.createElement( "link" );
    link.href = "css/estilos.css";
    link.rel = "stylesheet";
    document.getElementsByTagName( "head" )[0].appendChild( link );*/
  },
  methods: {
    confirm() {
      window.location.replace("https://wa.me/59893413880");
    },
  },
};
</script>

<style>
  button{
    margin: auto
  }
  
  .bg-color{
    background-color: #204BC3 !important
  }
  .list-group-flush>.list-group-item{
    border-width: 0px;
  }
  #styles{
    border-radius: 0px !important;
    border: 0px !important;
    box-shadow: none !important;
    position: fixed;
    top: 20%;
    text-align: left !important;
  }
  /*#app{
    background-color: #fa4f48;
    color: white;
  }*/
  #btn-chechform2{
    box-shadow: none !important;
	  color: #fa4f48 !important;
    font-size: 15px;
    background-color: white !important;
    padding-left: 35px;
    padding-right: 35px;
		padding-bottom: 13px;
    padding-top: 13px;
    margin-left: 0px !important;
  }
  .osomwhite{
    position: fixed;
    bottom: 0;
    left: 20%;
    right: 20%;
    font-size: 11px;
  }
  .logoOsom{
		width: 15%;
	}
</style>